@media print {

    @page {
        margin: 0;
        size: auto;
    }

    html, body {
        max-height: 99%;
    }

    body {
        padding: 3mm 5mm 0 5mm;
    }

    html, body, #root {
        background: #FFFFFF !important;
    }

    .text-white {
        color: #000000 !important;
        fill: #000000 !important;
    }
    
    hr {
        border: 1px solid black !important;
    }

    h1 {
        font-size: 50px;
    }

    h2 {
        font-size: 40px;
    }

    h5 {
        font-size: 20px;
    }
}

html, body, #root {
    height: 100%;
    background: #171717;
}

input:focus, input:active, select:focus, select:active {
    outline: none;
    box-shadow: none !important;
}

input:focus, select:focus {
    border-color: darkgray !important;
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

.striped .item:nth-child(odd) {
    border-bottom: 1px solid #676767;
}

img {
    max-width: 800px;
}

.error-message {
    min-height: 24px;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.logo {
    min-width: 180px;
    max-width: 180px;
    min-height: 180px;
    max-height: 180px;
}

.social-logo {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 20%;
}

.gmaps {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
}

.wisdom-font {
    font-family: Wisdom, serif;
}

.menu {
    border: 3px solid white;
}

.red-dot {
    display: block;
    height: 30px;
    width: 30px;
    line-height: 28px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: red;
    color: white;
    text-align: center;
    margin-bottom: 2px;
}

.arrow {
    font-size: 25px;
    margin-right: 4px;
    cursor: pointer;
}

hr {
    border: 1px solid white;
    margin: 0;
    padding: 0;
}

.categorybtn {
    font-size: 30px;
    padding-bottom: 0;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer !important;
}

.transition-all {
    transition: all 1s;
}